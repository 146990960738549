









































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';
import QsHtmlEditor from 'qs_vuetify/src/components/Forms/QsHtmlEditor.vue';

import { Form } from 'qs_vuetify/src/types/components';
import { NationalEvent } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';

@Component({
  components: {
    QsFormBuilder,
    QsHtmlEditor,
  },
})
export default class NationalEventsForm extends Vue {
  @Prop({ required: false, type: Object, default: null }) error!: ErrorResponse;
  @Prop({ required: true, type: Object }) form!: Form;
  @Prop({ required: true, type: Object }) item!: NationalEvent | null;
  @Prop({ required: true, type: Boolean }) loading!: boolean;
  @Prop({ required: true, type: String }) slug!: string;
}
