var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "instance-roles-list", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { class: { "no-gutters": _vm.section } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c(
                "div",
                { class: { "mb-3": !_vm.section } },
                [
                  _c("qs-export-menu", {
                    on: {
                      export: function($event) {
                        return _vm.exportAs($event)
                      }
                    }
                  })
                ],
                1
              ),
              _vm.instanceRolesFiltersDefinition
                ? _c("qs-filters", {
                    attrs: {
                      "active-filters": _vm.instanceRolesParams,
                      dense: !!_vm.section,
                      "filters-definition": _vm.instanceRolesFiltersDefinition,
                      "model-name": "instance_roles",
                      theme: "labase"
                    },
                    on: { input: _vm.setInstanceRolesParam }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "9" } },
            [
              _c("qs-data-table", {
                attrs: {
                  actions: ["edit", "archive"],
                  headers: _vm.tableHeaders,
                  items: _vm.instanceRoles,
                  loading: _vm.instanceRolesLoading,
                  options: _vm.instanceRolesOptions,
                  "set-options": _vm.setInstanceRolesOptions,
                  tile: "",
                  total: _vm.instanceRolesTotal || 0
                },
                on: { edit: _vm.emitEdit },
                scopedSlots: _vm._u([
                  {
                    key: "item.instance_name",
                    fn: function(ref) {
                      var item = ref.item
                      return [_vm._v(" " + _vm._s(item.instance_name) + " ")]
                    }
                  },
                  {
                    key: "item.contact.email",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(item.contact ? item.contact.email : "") +
                            " "
                        ),
                        item.contact && item.contact.email
                          ? _c("qs-copy-to-clipboard", {
                              attrs: {
                                size: "x-small",
                                text: item.contact.email
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "item.start_at",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " + _vm._s(_vm._f("date")(item.start_at)) + " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.end_at",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(" " + _vm._s(_vm._f("date")(item.end_at)) + " ")
                      ]
                    }
                  },
                  {
                    key: "item.is_active",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("qs-boolean-indicator", {
                          attrs: { value: item.is_active }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.responsibilities",
                    fn: function(ref) {
                      var item = ref.item
                      return _vm._l(item.responsibilities, function(r) {
                        return _c(
                          "v-chip",
                          {
                            key:
                              "instance-role-" +
                              item.id +
                              "__responsibility-" +
                              r.id,
                            staticClass: "mb-1 mr-1",
                            attrs: { "x-small": "" }
                          },
                          [_vm._v(" " + _vm._s(r.name) + " ")]
                        )
                      })
                    }
                  },
                  {
                    key: "item.actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-2",
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                return _vm.emitEdit(item)
                              }
                            }
                          },
                          [_vm._v(" mdi-pencil ")]
                        ),
                        item.is_active
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                attrs: { small: "" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.emitArchive(item)
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [_vm._v(" mdi-archive ")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [_vm._v(" Mettre fin ")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("users-settings-dialog", {
        attrs: { view: "section" },
        model: {
          value: _vm.usersSettingsDialogVisible,
          callback: function($$v) {
            _vm.usersSettingsDialogVisible = $$v
          },
          expression: "usersSettingsDialogVisible"
        }
      }),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }