var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "instance-elected-info" },
    [
      _c("span", { staticClass: "caption instance-elected-info__label" }, [
        _vm._v(_vm._s(_vm.label))
      ]),
      _c("br"),
      _c("v-divider", { staticClass: "mt-1 mb-1" }),
      _c("span", { staticClass: "body-1 font-weight-demi" }, [
        _vm._v(_vm._s(_vm.item.contact.full_name))
      ]),
      _c("br"),
      _vm.item.contact.home_phone
        ? _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("span", { staticClass: "body-2" }, [
                _vm._v(_vm._s(_vm._f("phone")(_vm.item.contact.home_phone)))
              ]),
              _c("br"),
              _c("v-spacer"),
              _c("qs-copy-to-clipboard", {
                attrs: {
                  size: "x-small",
                  text: _vm.$filters.phone(_vm.item.contact.home_phone)
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.item.contact.email
        ? _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("span", { staticClass: "body-2" }, [
                _vm._v(_vm._s(_vm.item.contact.email))
              ]),
              _c("br"),
              _c("v-spacer"),
              _c("qs-copy-to-clipboard", {
                attrs: { size: "x-small", text: _vm.item.contact.email }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }