











































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { RawLocation } from 'vue-router';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsBooleanIndicator from 'qs_vuetify/src/components/Indicators/QsBooleanIndicator.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';

import { Form } from 'qs_vuetify/src/types/components';
import { NationalEvent } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams } from 'qs_vuetify/src/types/states';

import NationalEventsForm from '@/components/NationalEvents/NationalEventsForm.vue';

const global: any = namespace('global');
const nationalEvents: any = namespace('national_events');
const view: any = namespace('nationalEventsView');

@Component({
  components: {
    NationalEventsForm,
    QsActionModal,
    QsBooleanIndicator,
    QsButton,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class NationalEventsDialog extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
) {
  @global.Getter previousLocation!: RawLocation | null;
  @global.Mutation addNotification!: any;
  @global.Mutation setPreviousLocation!: (location: RawLocation | null) => void;

  @nationalEvents.Getter error!: ErrorResponse;
  @nationalEvents.Getter form!: Form;
  @nationalEvents.Getter item!: NationalEvent | null;
  @nationalEvents.Getter loading!: boolean;
  @nationalEvents.Getter slug!: string;
  @nationalEvents.Mutation('item') syncItem!: any

  @view.Getter params!: RestParams;

  @Prop([String, Number]) id!: string | number;

  mounted() {
    this.onRouteChanged();
  }

  get itemReady(): boolean {
    if (this.id === 'new') {
      return !!this.item;
    }

    if (typeof this.id === 'string') {
      return this.item?.id === parseInt(this.id, 10);
    }

    return this.item?.id === this.id;
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged(val: boolean) {
    if (val) {
      this.onRouteChanged();
    }
  }

  @Watch('itemReady')
  onItemReady(ready: boolean) {
    if (ready) {
      this.setGlobalSubtitle();
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.setGlobalSubtitle();
  }

  closeModal() {
    this.$store.commit(`${this.slug}/item`, null);

    if (this.previousLocation) {
      this.$router.push(this.previousLocation);
      this.setPreviousLocation(null);
    } else {
      this.$router.push({
        name: 'NationalEventsList',
      });
    }
  }

  setGlobalSubtitle() {
    if (!this.itemReady) {
      this.$store.commit('global/subtitle', 'Chargement...');
    } else if (this.isNew) {
      this.$store.commit(
        'global/subtitle', this.item?.name
        || 'Nouvel événement national',
      );
    } else {
      this.$store.commit(
        'global/subtitle', this.item?.name
        || `Événement national #${this.item?.id || '?'}`,
      );
    }
    this.$emit('updateHead');
  }

  async submitForm() {
    if (!this.item) {
      return;
    }

    try {
      await this.submit();
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: "Erreur lors de la sauvegarde de l'événement national.",
        timeout: 2500,
      });
      return;
    }

    this.$store.commit('national_events/lastLoadedAt', null);
    this.reloadDataRoutesData(['national_events.index']);

    this.closeModal();
  }
}
