































































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { RawLocation } from 'vue-router';

import dayjs from 'qs_vuetify/src/plugins/dayjs';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsBooleanIndicator from 'qs_vuetify/src/components/Indicators/QsBooleanIndicator.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';

import { Form } from 'qs_vuetify/src/types/components';
import { Instance, InstanceRole } from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { RestParams } from 'qs_vuetify/src/types/states';

import InstanceRolesForm from '@/components/InstanceRoles/InstanceRolesForm.vue';

const global: any = namespace('global');
const instances: any = namespace('instances');
const instancesRoles: any = namespace('instance_roles');
const view: any = namespace('instancesView');

@Component({
  beforeRouteLeave(to, from, next) {
    this.$store.commit('contacts/search', []);
    next();
  },
  components: {
    InstanceRolesForm,
    QsActionModal,
    QsBooleanIndicator,
    QsButton,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class InstanceRolesDialog extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
) {
  @global.Getter previousLocation!: RawLocation | null;
  @global.Mutation setPreviousLocation!: (location: RawLocation | null) => void;

  @instances.Getter('item') instance!: Instance | null;

  @instancesRoles.Getter error!: ErrorResponse;
  @instancesRoles.Getter form!: Form;
  @instancesRoles.Getter item!: InstanceRole | null;
  @instancesRoles.Getter loading!: boolean;
  @instancesRoles.Getter slug!: string;
  @instancesRoles.Mutation('item') syncItem!: any

  @view.Getter instanceRolesParams!: RestParams;

  @Prop([String, Number]) id!: string | number;
  @Prop([String, Number]) roleId!: string | number;
  @Prop([String, Number]) section!: string | number;

  mounted() {
    this.onRouteChanged();
  }

  get itemReady(): boolean {
    if (this.roleId === 'new') {
      return !!this.item;
    }

    if (typeof this.roleId === 'string') {
      return this.item?.id === parseInt(this.roleId, 10);
    }

    if (typeof this.roleId === 'number') {
      return this.item?.id === this.roleId;
    }

    if (this.id === 'new') {
      return !!this.item;
    }

    return this.item?.id === this.id;
  }

  // eslint-disable-next-line class-methods-use-this
  get viewParams(): { [key: string]: RestParams } {
    return {
      instance_roles: {
        fields: [
          '*',
          'contact.id',
          'contact.district.name',
          'contact.full_name',
          'contact.status',
          'contact.v1_contact_id',
          'instance_role_type.name',
          'instance.id',
          'instance.name',
          'responsibilities.name',
        ].join(','),
      },
    };
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged(val: boolean) {
    if (val) {
      this.onRouteChanged();
    }
  }

  @Watch('itemReady')
  onItemReady(ready: boolean) {
    if (ready) {
      this.setGlobalSubtitle();
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.setGlobalSubtitle();
  }

  closeModal() {
    this.$store.commit(`${this.slug}/item`, null);

    if (this.previousLocation) {
      this.$router.push(this.previousLocation);
      this.setPreviousLocation(null);
    } else if (this.$route.name === 'InstancesInstanceRolesDialog') {
      this.$router.push({
        name: 'InstancesForm',
        params: {
          id: `${this.instance?.id}`,
          section: 'roles',
        },
      });
    } else {
      this.$router.push({
        name: 'InstanceRolesList',
        params: {
          id: `${this.instance?.id}`,
          section: 'roles',
        },
      });
    }
  }

  endRole(): void {
    const now = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');

    if (this.item) {
      this.item.end_at = now;
    }

    this.submitForm();
  }

  setGlobalSubtitle() {
    if (!this.itemReady) {
      this.$store.commit('global/subtitle', 'Chargement...');
    } else if (this.isNew) {
      this.$store.commit(
        'global/subtitle', this.item?.contact?.full_name
        || 'Nouveau rôle',
      );
    } else {
      this.$store.commit(
        'global/subtitle', this.item?.contact?.full_name
        || `Rôle #${this.item?.id || '?'}`,
      );
    }
    this.$emit('updateHead');
  }

  async submitForm() {
    if (!this.item) {
      return;
    }

    await this.submit();

    this.$store.commit('instance_roles/lastLoadedAt', null);
    this.reloadDataRoutesData(['instance_roles.index']);

    this.closeModal();
  }
}
