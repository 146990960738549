<template>
  <v-app>
    <qs-layout-navigation
      :help="help"
      :primary-menu="primaryMenuItems"
      theme="labase"
      version-link="https://docs.google.com/document/d/1HBDRvizN4_RO1FBS4QZawprLZQhomTeGzxIHwdvUU5c/edit?usp=sharing"
      :version-number="version"
      @login="loadUserOrPrompt"
    />

    <v-main>
      <qs-layout-notifications />

      <router-view />

      <qs-confirmation-modal
        v-bind="confirmationDialog"
        @click:confirm="runConfirmationDialogCallback"
        @click:cancel="resetConfirmationDialog"
      />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';

import QsConfirmationModal from 'qs_vuetify/src/components/Dialog/QsConfirmationModal.vue';
import QsLayoutNavigation from 'qs_vuetify/src/components/Layout/QsLayoutNavigation.vue';
import QsLayoutNotifications from 'qs_vuetify/src/components/Layout/QsLayoutNotifications.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import MenusMixin from 'qs_vuetify/src/mixins/MenusMixin';

import { ButtonProps, ConfirmationDialog, MenuItem } from 'qs_vuetify/src/types/components';

import { routes } from '@/router/index';

export const userFields = [
  'id', 'email', 'superadmin', 'contact_id', 'contact_name',
  'unread_notifications', 'default_instance_id', 'accepted_confidentiality_agreement',
  'rights_slugs', 'inherited_instances.id', 'inherited_instances.name',
].join(',');

@Component({
  components: {
    QsConfirmationModal,
    QsLayoutNavigation,
    QsLayoutNotifications,
  },
})
export default class App extends mixins(AuthenticationMixin, MenusMixin) {
  @Action('checkUser', { namespace: 'auth' }) checkUser!: any;
  @Action('loadUser', { namespace: 'auth' }) loadUser!: any;
  @Action('logout', { namespace: 'auth' }) logout!: any;
  @Getter('token', { namespace: 'auth' }) token!: string | null;

  @Getter('confirmationDialog', { namespace: 'global' }) confirmationDialog!: ConfirmationDialog;
  @Getter('help', { namespace: 'global' }) help!: ButtonProps | null;
  @Mutation('resetConfirmationDialog', { namespace: 'global' }) resetConfirmationDialog!: any;
  @Mutation('runConfirmationDialogCallback', { namespace: 'global' }) runConfirmationDialogCallback!: any;

  get primaryMenuItems(): Array<MenuItem> {
    const submenus = [
      { slug: 'config', name: 'Configuration' },
    ];
    return [
      ...this.buildPrimaryMenuItems(routes),
      ...this.filterParentMenuItems(
        submenus.map((i) => this.buildParentMenuItem(routes, i.slug, i.name)),
      ),
    ];
  }

  get version(): string {
    return this.$store.state.version;
  }

  async mounted() {
    await this.loadUserOrPrompt();
  }

  async loadUserOrPrompt() {
    if (!this.user) {
      if (this.token) {
        try {
          await this.loadUser(userFields);

          this.onAppUserIsConnectedChanged(true);
        } catch (e) {
          await this.logout();

          return this.redirectToHomeAndShowLoginDialog();
        }
      } else {
        return this.redirectToHomeAndShowLoginDialog();
      }
    } else {
      try {
        await this.checkUser('id');

        this.loadUser(userFields);

        this.onAppUserIsConnectedChanged(true);
      } catch (e) {
        await this.logout();

        return this.redirectToHomeAndShowLoginDialog();
      }
    }

    return true;
  }

  redirectToHomeAndShowLoginDialog() {
    const {
      fullPath,
      name: routeName,
    } = this.$route;

    const redirect = routeName ? fullPath : document.location.pathname;

    if (routeName !== 'Home') {
      this.$router.push(`/${redirect ? `?r=${encodeURIComponent(redirect)}` : ''}`);
    }

    this.$store.commit('global/loginDialog', true);

    return true;
  }

  @Watch('userIsConnected')
  onAppUserIsConnectedChanged(connected: boolean) {
    if (connected) {
      if (this.$route.query && this.$route.query.r) {
        this.$router.push(`${this.$route.query.r}`);
      }

      this.$store.dispatch('districts/index', { order: 'name', per_page: '*' });
    } else {
      this.$router.push('/');
    }
  }
}
</script>
