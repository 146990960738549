import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';

import { DataCollections } from 'qs_vuetify/src/mixins/DataRouteGuards';
import i18n from 'qs_vuetify/src/plugins/i18n';
import { capitalize } from 'qs_vuetify/src/plugins/filters';
import { buildRestRoutes } from 'qs_vuetify/src/store/buildBaseRoute';

import { MenuItem } from 'qs_vuetify/src/types/components';
import { Model } from 'qs_vuetify/src/types/models';

import instances from './instances';
import instanceRoles from './instanceRoles';
import nationalEvents from './nationalEvents';
import petitions from './petitions';
import store from '../store';
import users from './users';

const Home = () => import('@/views/Home.vue');
const InstanceEventTypesForm = () => import('@/views/InstanceEventTypes/InstanceEventTypesForm.vue');
const InstanceEventTypesList = () => import('@/views/InstanceEventTypes/InstanceEventTypesList.vue');
const InstanceRoleTypesForm = () => import('@/views/InstanceRoleTypes/InstanceRoleTypesForm.vue');
const InstanceRoleTypesList = () => import('@/views/InstanceRoleTypes/InstanceRoleTypesList.vue');
const InstanceRoleResponsibilitiesForm = () => { // eslint-disable-line arrow-body-style
  return import('@/views/InstanceRoleResponsibilities/InstanceRoleResponsibilitiesForm.vue');
};
const InstanceRoleResponsibilitiesList = () => { // eslint-disable-line arrow-body-style
  return import('@/views/InstanceRoleResponsibilities/InstanceRoleResponsibilitiesList.vue');
};
const InstanceTypesForm = () => import('@/views/InstanceTypes/InstanceTypesForm.vue');
const InstanceTypesList = () => import('@/views/InstanceTypes/InstanceTypesList.vue');
const RightGroupDialog = () => import('@/views/RightGroups/RightGroupDialog.vue');
const RightGroups = () => import('@/views/RightGroups/RightGroups.vue');
const RightsForm = () => import('@/views/Rights/RightsForm.vue');
const RightsList = () => import('@/views/Rights/RightsList.vue');
const NewRocketchatChannelsForm = () => import('@/views/RocketchatChannels/NewRocketchatChannelsForm.vue');
const RocketchatChannelsForm = () => import('@/views/RocketchatChannels/RocketchatChannelsForm.vue');
const RocketchatChannelsList = () => import('@/views/RocketchatChannels/RocketchatChannelsList.vue');
const VolunteersReport = () => import('@/views/Reports/VolunteersReport.vue');

declare module 'vue-router/types/router' {
  interface Route {
    meta?: any & {
      data?: DataCollections<Model>;
    } & MenuItem;
  }

  interface _RouteConfigBase { // eslint-disable-line @typescript-eslint/class-name-casing
    meta?: any & {
      data?: DataCollections<Model>;
    } & MenuItem;
  }
}

Vue.use(VueRouter);

export const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      auth: true,
      data: {
        instance_events: {
          index: {
            params: {
              fields: '*,instance_event_type.name,created_by_user.contact_name,instance.name',
              order: '-date',
              per_page: 20,
            },
          },
        },
      },
      title: 'Accueil',
      menus: ['primary'],
    },
  },
  ...instances,
  ...instanceRoles,
  ...users,
  ...petitions,
  ...nationalEvents,
  {
    path: '/reports/volunteers',
    name: 'VolunteersReport',
    component: VolunteersReport,
    meta: {
      auth: true,
      data: {
        filters: {
          index: {
            params: {
              repository: 'Instance',
              per_page: '*',
            },
          },
        },
      },
      menus: ['reports'],
      requires: ['INSTANCES_RETRIEVE', 'INSTANCE_ROLES_RETRIEVE'],
      title: 'Militant·es | Rapports',
    },
  },
  ...buildRestRoutes({
    list: InstanceTypesList,
    genericForm: InstanceTypesForm,
    menus: ['config'],
    slug: 'instance_types',
  }),
  ...buildRestRoutes({
    list: InstanceEventTypesList,
    genericForm: InstanceEventTypesForm,
    menus: ['config'],
    slug: 'instance_event_types',
  }),
  ...buildRestRoutes({
    list: InstanceRoleTypesList,
    genericForm: InstanceRoleTypesForm,
    menus: ['config'],
    slug: 'instance_role_types',
  }),
  ...buildRestRoutes({
    list: InstanceRoleResponsibilitiesList,
    genericForm: InstanceRoleResponsibilitiesForm,
    menus: ['config'],
    slug: 'instance_role_responsibilities',
  }),
  {
    path: '/right_groups',
    name: 'RightGroups',
    component: RightGroups,
    meta: {
      data: {
        right_groups: {
          index: {
            params: {
              fields: 'id,name,description,rights.id',
              order: 'name',
            },
          },
        },
      },
      title: capitalize(i18n.tc('models.right_groups.name', 2)),
      menus: ['config'],
      submenu: false,
      requires: ['RIGHT_GROUPS_RETRIEVE'],
    },
    children: [
      {
        component: RightGroupDialog,
        name: 'RightGroupDialog',
        path: ':id',
        props: true,
        meta: {
          data: {
            right_groups: {
              loadEmpty: {
                conditional(vm: Vue) {
                  return vm.$route.params.id === 'new';
                },
              },
              index: {},
              retrieve: {
                conditional(vm: Vue) {
                  return vm.$route.params.id !== 'new';
                },
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.id,
                  };
                },
                params: {
                  fields: '*,rights.*',
                },
              },
            },
            rights: {
              index: {
                params: {
                  per_page: '*',
                },
              },
            },
          },
        },
      },
    ],
  },
  ...buildRestRoutes({
    list: RightsList,
    genericForm: RightsForm,
    menus: ['config'],
    slug: 'rights',
  }),
  ...buildRestRoutes({
    list: RocketchatChannelsList,
    newForm: NewRocketchatChannelsForm,
    persistedForm: RocketchatChannelsForm,
    slug: 'rocketchat_channels',
  }),
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit('global/reset');
  next();
});

export default router;
