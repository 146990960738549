var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "qs-action-modal",
    {
      attrs: {
        color: "white",
        "content-class": "national-events-dialog",
        dark: false,
        height: "70vh",
        value: true,
        "max-width": "1000"
      },
      on: { "click:close": _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _vm.item && _vm.itemReady
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.isNew
                          ? "Ajouter un événement national"
                          : _vm.item.name
                      )
                    )
                  ])
                : _c("span", [_vm._v("Chargement...")])
            ]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "qs-button",
                {
                  attrs: {
                    color: "primary",
                    depressed: "",
                    disabled: !_vm.itemReady || _vm.loading,
                    loading: _vm.loading,
                    tile: ""
                  },
                  on: { click: _vm.submitForm }
                },
                [_vm._v(" Enregistrer ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.item && _vm.form && _vm.itemReady
        ? _c("national-events-form", {
            attrs: {
              error: _vm.error,
              form: _vm.form,
              item: _vm.item,
              loading: _vm.loading,
              slug: _vm.slug
            },
            on: { input: _vm.syncItem }
          })
        : _c(
            "div",
            {
              staticClass: "d-flex justify-center align-center",
              staticStyle: { height: "50vh" }
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }