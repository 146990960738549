



























import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';

import { Instance } from 'qs_vuetify/src/types/models';

import { Form } from 'qs_vuetify/src/types/components';

import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';

const instances: any = namespace('instances');

@Component({
  components: {
    QsFormBuilder,
  },
})
export default class InstanceExtraCard extends Vue {
  @instances.Getter item!: Instance;

  @Prop({ required: true, type: Object }) meta!: {
    emails: string;
  };

  description = 'Un courriel par ligne.';

  get forms(): { header: string; form: Form }[] {
    return [
      {
        header: 'Courriels',
        form: {
          message_1: {
            description: `Lorsque l'étiquette nationale Bénévole2022 est ajoutée automatiquement à un
              Contact appartenant à cette Instance ou lorsqu'une étiquette "Bénévole2002: ${this.instanceName}"
              est ajoutée automatiquement ou manuellement à n'importe quel Contact, un courriel sera envoyé
              aux adresses ci-dessous. Laissez vide pour ne pas utiliser cette fonction dans cette Instance.`,
            type: 'paragraph',
          },
          emails: {
            label: 'Courriels',
            description: this.description,
            rules: {
              custom: (value: string) => {
                if (!value) {
                  return true;
                }

                const parts = value.split('\n');
                const index: Record<string, boolean> = {};

                if (parts.length > 0) {
                  for (let i = 0, len = parts.length; i < len; i += 1) {
                    if (!this.$helpers.isValidEmail(parts[i].trim()) && parts[i].trim().length > 0) {
                      if (i !== 0 || parts[i].length > 5) {
                        return `Courriel invalide. ${this.description}`;
                      }
                    }

                    if (index[parts[i]]) {
                      return `Courriel en double. ${this.description}`;
                    }

                    index[parts[i]] = true;
                  }
                }

                return true;
              },
            },
            type: 'textarea',
          },
        },
      },
      {
        header: 'Fiche de contribution',
        form: {
          paper_form_code: {
            label: "Code unique de la fiche de contribution de l'instance",
            type: 'text',
          },
          paper_form_url: {
            label: "Lien vers la fiche de contribution de l'instance",
            type: 'text',
          },
        },
      },
      {
        header: 'Objectifs de financement',
        form: {
          contribution_target: {
            label: "Objectif de financement pour l'instance pour l'année en cours.",
            type: 'text',
          },
          mid_contribution_target: {
            label: "Objectif de financement au 30 juin pour l'instance pour l'année en cours.",
            type: 'text',
          },
          contribution_message: {
            label: "Message de financement pour l'instance pour l'année en cours.",
            type: 'textarea',
          },
        },
      },
      {
        header: 'Conseil national / congrès',
        form: {
          delegation_confirmation_url: {
            label: 'Message de financement',
            type: 'text',
          },
          delegation_size: {
            label: 'Taille maximale de la délégation',
            type: 'number',
          },
        },
      },
    ];
  }

  get instanceName(): string {
    if (this.item) {
      return this.item.name || '';
    }

    return '';
  }
}
