














































































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { RawLocation } from 'vue-router';

import QsActionModal from 'qs_vuetify/src/components/Dialog/QsActionModal.vue';
import QsBooleanIndicator from 'qs_vuetify/src/components/Indicators/QsBooleanIndicator.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ExportMixin from 'qs_vuetify/src/mixins/ExportMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import { NationalEvent, NationalEventParticipation } from 'qs_vuetify/src/types/models';
import { RestParams } from 'qs_vuetify/src/types/states';

const global: any = namespace('global');
const nationalEvents: any = namespace('national_events');
const nationalEventParticipations: any = namespace('national_event_participations');
const view: any = namespace('nationalEventParticipationsView');

@Component({
  beforeRouteLeave(to, from, next) {
    this.$store.commit('contacts/search', []);
    next();
  },
  components: {
    QsActionModal,
    QsBooleanIndicator,
    QsButton,
    QsDataTable,
  },
  head: {
    title() {
      const { title, subtitle } = this.$store.state.global;
      let inner = this.$route.matched.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          return r.meta.title;
        }
        return acc;
      }, title);
      if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class NationalEventParticipationsDialog extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ExportMixin,
  ListMixin,
) {
  @global.Getter previousLocation!: RawLocation | null;
  @global.Mutation addNotification!: any;
  @global.Mutation setPreviousLocation!: (location: RawLocation | null) => void;

  @nationalEvents.Getter item!: NationalEvent;

  @nationalEventParticipations.Getter data!: Array<NationalEventParticipation>;
  @nationalEventParticipations.Getter exportUrl!: string;
  @nationalEventParticipations.Getter loading!: boolean;
  @nationalEventParticipations.Getter slug!: string;
  @nationalEventParticipations.Getter total!: number;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Mutation('setOptions') setOptions!: any;
  @view.Mutation('setParams') setParams!: any;

  @Prop([String, Number]) id!: string | number;

  setParam = this.buildSetParam('national_event_participations', this.setParams);

  mounted() {
    this.onRouteChanged();
  }

  // eslint-disable-next-line class-methods-use-this
  get exportParams() {
    return this.viewParams.national_event_participations;
  }

  get itemReady(): boolean {
    if (this.id === 'new') {
      return !!this.item;
    }

    if (typeof this.id === 'string') {
      return this.item?.id === parseInt(this.id, 10);
    }

    return this.item?.id === this.id;
  }

  tableHeaders = [
    { text: 'No de membre', value: 'v1_contact_id', sortable: false },
    { text: 'Nom', value: 'contact', sortable: false },
    { text: 'Genre', value: 'gender', sortable: false },
    { text: 'Instance', value: 'instance', sortable: false },
    { text: 'Type', value: 'type', sortable: false },
    { text: 'Statut', value: 'state', sortable: false },
    { text: 'Confirmée', value: 'confirmed', sortable: false },
  ];

  get viewParams() {
    return {
      national_event_participations: {
        ...ListMixin.buildListState(
          this.options,
          this.params,
        ),
        order: 'instance.name,contact.full_name',
      },
    };
  }

  @Watch('routeDataLoaded')
  onRouteDataLoadedChanged(val: boolean) {
    if (val) {
      this.onRouteChanged();
    }
  }

  @Watch('itemReady')
  onItemReady(ready: boolean) {
    if (ready) {
      this.setGlobalSubtitle();
    }
  }

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.setGlobalSubtitle();
  }

  closeModal() {
    if (this.previousLocation) {
      this.$router.push(this.previousLocation);
      this.setPreviousLocation(null);
    } else {
      this.$router.push({
        name: 'NationalEventsList',
      });
    }
  }

  async exportValidationCsv() {
    await this.exportAsWithParams('text/csv', {
      fields: 'label,contact.v1_contact_id,validation_secret',
      order: 'type,contact.full_name',
    });
  }

  setGlobalSubtitle() {
    if (!this.itemReady) {
      this.$store.commit('global/subtitle', 'Chargement...');
    } else {
      this.$store.commit(
        'global/subtitle', this.item?.name
        || `Événement national #${this.item?.id || '?'}`,
      );
    }
    this.$emit('updateHead');
  }
}
