import type { RouteConfig } from 'vue-router';

import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

const NationalEventsList = () => import('@/views/NationalEvents/NationalEventsList.vue');
const NationalEventsDialog = () => import('@/views/NationalEvents/NationalEventsDialog.vue');
const NationalEventParticipationsDialog = () => import('@/views/NationalEvents/NationalEventParticipationsDialog.vue');

const nationalEvents: RouteConfig[] = [
  {
    path: '/national_events',
    name: 'NationalEventsList',
    component: NationalEventsList,
    meta: {
      data: {
        national_events: {
          index: {
            contextual(vm: Vue) {
              const { options, params } = vm.$store.state.nationalEventsView;
              return {
                ...ListMixin.buildListState(options, params),
              };
            },
            params: {
              fields: '*,participations_count',
            },
          },
        },
      },
      title: 'Événements nationaux',
      menus: ['primary'],
      requires: ['NATIONAL_EVENTS_RETRIEVE'],
    },
    children: [
      {
        path: ':id/participations',
        name: 'NationalEventsParticipationsDialog',
        component: NationalEventParticipationsDialog,
        props: true,
        meta: {
          data: {
            national_events: {
              index: {
                contextual(vm: Vue) {
                  const { options, params } = vm.$store.state.nationalEventsView;
                  return {
                    ...ListMixin.buildListState(options, params),
                  };
                },
                params: {
                  fields: '*,participations_count',
                },
              },
              loadEmpty: {
                conditional(vm: Vue) {
                  return vm.$route.params.id === 'new';
                },
              },
              retrieve: {
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.id,
                  };
                },
                conditional(vm: Vue) {
                  return vm.$route.params.id !== 'new';
                },
              },
            },
            national_event_participations: {
              index: {
                contextual(vm: Vue) {
                  return {
                    national_event_id: vm.$route.params.id,
                  };
                },
                params: {
                  fields: '*,contact.v1_contact_id,contact.full_name,contact.gender,instance.name',
                  order: 'instance.name,contact.full_name',
                },
              },
            },
          },
          title: 'Événement national',
          menus: [],
          requires: ['NATIONAL_EVENTS_RETRIEVE'],
        },
      },
      {
        path: ':id',
        name: 'NationalEventsDialog',
        component: NationalEventsDialog,
        props: true,
        meta: {
          data: {
            national_events: {
              index: {
                contextual(vm: Vue) {
                  const { options, params } = vm.$store.state.nationalEventsView;
                  return {
                    ...ListMixin.buildListState(options, params),
                  };
                },
                params: {
                  fields: '*,participations_count',
                },
              },
              loadEmpty: {
                conditional(vm: Vue) {
                  return vm.$route.params.id === 'new';
                },
              },
              retrieve: {
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.id,
                  };
                },
                conditional(vm: Vue) {
                  return vm.$route.params.id !== 'new';
                },
              },
            },
          },
          title: 'Événement national',
          menus: [],
          requires: ['NATIONAL_EVENTS_RETRIEVE'],
        },
      },
    ],
  },
];

export default nationalEvents;
