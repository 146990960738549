











































































































































































































































import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import QsBooleanIndicator from 'qs_vuetify/src/components/Indicators/QsBooleanIndicator.vue';
import QsButton from 'qs_vuetify/src/components/Buttons/QsButton.vue';
import QsCard from 'qs_vuetify/src/components/QsCard.vue';
import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';
import QsFormBuilder from 'qs_vuetify/src/components/QsFormBuilder.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import FormMixin from 'qs_vuetify/src/mixins/FormMixin';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { DataTableOptions, Form } from 'qs_vuetify/src/types/components';
import {
  Contact,
  Instance,
  InstanceEvent,
  InstanceRole,
} from 'qs_vuetify/src/types/models';
import { ErrorResponse } from 'qs_vuetify/src/types/responses';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

import InstanceContactsList from '@/components/InstanceContacts/InstanceContactsList.vue';
import InstanceElectedInfo from '@/components/Instances/InstanceElectedInfo.vue';
import InstanceExtraCard from '@/components/Instances/InstanceExtraCard.vue';
import InstanceStats from '@/components/Instances/InstanceStats.vue';
import MemberAdoptionDialog from '@/components/Dialog/MemberAdoptionDialog.vue';
import QsInstanceEventsList from '@/components/QsInstanceEventsList.vue';
import UsersSettingsDialog from '@/components/Dialog/UsersSettingsDialog.vue';
import VolunteersMassImportDialog from '@/components/Dialog/VolunteersMassImportDialog.vue';
import WarningIndicator from '@/components/Instances/WarningIndicator.vue';

import InstanceGrantsList from '@/views/InstanceGrants/InstanceGrantsList.vue';
import InstanceRolesList from '@/views/InstanceRoles/InstanceRolesList.vue';
import InstanceUsersList from '@/views/InstanceUsers/InstanceUsersList.vue';
import InstanceEventsListDetail from '../InstanceEvents/InstanceEventsListDetail.vue';

const auth: any = namespace('auth');
const global: any = namespace('global');
const instances: any = namespace('instances');
const instanceEvents: any = namespace('instance_events');
const instanceRoles: any = namespace('instance_roles');
const instanceUsers: any = namespace('instance_users');
const view: any = namespace('instancesView');

@Component({
  beforeRouteLeave(to, from, next) {
    this.$store.commit('instances/item', null);
    next();
  },
  components: {
    InstanceContactsList,
    InstanceElectedInfo,
    InstanceEventsListDetail,
    InstanceGrantsList,
    InstanceExtraCard,
    InstanceRolesList,
    InstanceStats,
    InstanceUsersList,
    MemberAdoptionDialog,
    QsBooleanIndicator,
    QsButton,
    QsCard,
    QsDataTable,
    QsFilters,
    QsFormBuilder,
    QsInstanceEventsList,
    UsersSettingsDialog,
    VolunteersMassImportDialog,
    WarningIndicator,
  },
  head: {
    title(this: InstancesForm) {
      const { subtitle, title } = this.$store.state.global;
      const section = this.$t(`sections.${this.section}`);
      return {
        inner: `${subtitle || section} | ${title || this.$route.meta.title}`,
      };
    },
  },
})
export default class InstancesForm extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  FormMixin,
  ListMixin,
) {
  @Prop([String, Number]) id!: string | number;
  @Prop(String) section!: string;

  @auth.Action loadInstance!: any;
  @auth.Getter('ajax') userAjax!: Promise<any>;

  @global.Mutation showConfirmationDialog!: any;

  @instances.Getter error!: ErrorResponse;
  @instances.Getter initialItem!: string;
  @instances.Getter item!: Instance;
  @instances.Getter form!: Form;
  @instances.Getter loading!: boolean;
  @instances.Getter slug!: string;
  @instances.Getter stats!: any;
  @instances.Getter statsLoaded!: boolean;
  @instances.Mutation('item') syncItem!: any

  @instanceEvents.Getter('data') instanceEvents!: Array<InstanceEvent>;
  @instanceEvents.Getter('filtersDefinition') instanceEventsFiltersDefinition!: FiltersDefinition;
  @instanceEvents.Getter('loading') instanceEventsLoading!: boolean;
  @instanceEvents.Getter('total') instanceEventsTotal!: number;

  @instanceRoles.Getter('data') instanceRoles!: Array<Contact>;
  @instanceRoles.Getter('filtersDefinition') instanceRolesFiltersDefinition!: FiltersDefinition;
  @instanceRoles.Getter('loading') instanceRolesLoading!: boolean;
  @instanceRoles.Getter('total') instanceRolesTotal!: number;

  @instanceUsers.Getter('data') instanceUsers!: Array<Contact>;
  @instanceUsers.Getter('filtersDefinition') instanceUsersFiltersDefinition!: FiltersDefinition;
  @instanceUsers.Getter('loading') instanceUsersLoading!: boolean;
  @instanceUsers.Getter('total') instanceUsersTotal!: number;

  @view.Getter contactsOptions!: DataTableOptions;
  @view.Getter contactsParams!: RestParams;
  @view.Getter instanceEventsParams!: RestParams;
  @view.Getter instanceGrantsOptions!: DataTableOptions;
  @view.Getter instanceGrantsParams!: RestParams;
  @view.Getter instanceRolesOptions!: DataTableOptions;
  @view.Getter instanceRolesParams!: RestParams;
  @view.Getter instanceUsersOptions!: DataTableOptions;
  @view.Getter instanceUsersParams!: RestParams;

  @view.Mutation showAdoptionModal!: () => void;
  @view.Mutation('contactsOptions') setContactsOptions!: any;
  @view.Mutation('contactsParams') setContactsParams!: any;
  @view.Mutation('instanceEventsParams') setInstanceEventsParams!: any;
  @view.Mutation('instanceRolesOptions') setInstanceRolesOptions!: any;
  @view.Mutation('instanceUsersOptions') setInstanceUsersOptions!: any;
  @view.Mutation('instanceUsersParams') setInstanceUsersParams!: any;

  formOrder = [
    'name',
    'filter',
    'email',
    'instance_type_id',
    'can_adopt',
    'parent_id',
    'district_id',
    'region_id',
    'external_id',
  ];

  instanceRolesArchiveLoading = false;
  instanceUsersArchiveLoading = false;
  volunteersMassImportDialogVisible = false;
  roleModalVisible = false;
  roleModalItem: InstanceRole | null = null;
  usersSettingsDialogVisible = false;

  get adoptionModalVisible(): boolean {
    return this.$store.getters['instancesView/adoptionModalVisible'];
  }

  set adoptionModalVisible(val: boolean) {
    this.$store.commit('instancesView/adoptionModalVisible', val);
  }

  get instanceId(): number {
    return parseInt(`${this.id}`, 10);
  }

  // eslint-disable-next-line class-methods-use-this
  get labaseURL(): string {
    return process.env.VUE_APP_LABASE_URL;
  }

  get viewParams() {
    const instanceFields = [
      'active_coordination.contact.email',
      'active_coordination.contact.full_name',
      'active_coordination.contact.home_phone',
      'active_representant_officiel.contact.email',
      'active_representant_officiel.contact.full_name',
      'active_representant_officiel.contact.home_phone',
      'can_adopt',
      'district_id',
      'district.name',
      'email',
      'external_id',
      'filter',
      'id',
      'instance_type.*',
      'instance_type_id',
      'is_active',
      'meta',
      'name',
      'parent_id',
      'parent.name',
      'region_id',
      'region.name',
    ];

    return {
      instances: {
        fields: instanceFields.join(','),
      },
      'instances.stats': {
        fields: [
          'contacts',
          'last_events.*',
          'last_events.instance_event_type.name',
          'last_events.created_by_user.contact_name',
          'roles',
        ].join(','),
      },
      contacts: ListMixin.buildListState(this.contactsOptions, this.contactsParams),
      instance_events: {
        ...ListMixin.buildListState(this.instanceEventsParams),
        fields: '*,created_by_user.contact.full_name,instance_event_type.name',
      },
      instance_grants: ListMixin.buildListState(
        this.instanceGrantsOptions,
        this.instanceGrantsParams,
      ),
      'instance_roles.index': ListMixin.buildListState(
        this.instanceRolesOptions,
        this.instanceRolesParams,
      ),
      instance_users: ListMixin.buildListState(
        this.instanceUsersOptions,
        this.instanceUsersParams,
      ),
    };
  }

  resetViewParams() {
    this.setContactsParams({});
  }

  setContactsParam = this.buildSetParam('contacts', this.setContactsParams);
  setInstanceEventsParam = this.buildSetParam('instance_events', this.setInstanceEventsParams);
  setInstanceUsersParam = this.buildSetParam('instance_users', this.setInstanceUsersParams);

  syncMeta(meta: any) {
    this.syncItem({
      ...this.item,
      meta: {
        ...meta,
      },
    });
  }

  @Watch('hasChanged')
  onInstancesFormHasChangedChanged() {
    this.onInstancesFormRouteDataLoadedChanged(true);
  }

  @Watch('section')
  async onSectionChanged() {
    if (!this.$route.meta?.data) {
      return;
    }

    const sourcesExceptInstancesIndex = Object.keys(this.$route.meta.data)
      .filter((s) => s !== 'instances');
    sourcesExceptInstancesIndex.push('instances.stats');
    sourcesExceptInstancesIndex.push('instances.grants');
    try {
      await this.reloadDataRoutesData(sourcesExceptInstancesIndex);
    } catch (e) {
      if (e.status === 500) {
        this.resetViewParams();
      }
    }

    this.setGlobalTitle();
  }

  @Watch('$route.path')
  onInstancesFormRoutePathChanged() {
    if (this.routeDataLoaded) {
      this.onInstancesFormRouteDataLoadedChanged(true);
    }

    this.onSectionChanged();
  }

  @Watch('instanceRolesLoading')
  onInstancesFormInstancesRolesLoadingChanged() {
    this.onInstancesFormRouteDataLoadedChanged(true);
  }

  @Watch('instanceUsersLoading')
  onInstancesFormInstancesUsersLoadingChanged() {
    this.onInstancesFormRouteDataLoadedChanged(true);
  }

  @Watch('routeDataLoaded')
  async onInstancesFormRouteDataLoadedChanged(loaded: boolean) {
    await this.userAjax;

    if (!this.rightsLoaded) {
      try {
        await this.loadInstance({
          id: this.instanceId,
          direct: (this.user?.instances || []).findIndex((i) => i.id === this.instanceId) !== -1,
          fields: 'id,name,rights_slugs',
        });
      } catch (e) {
        if (!this.userIsSuperadmin) {
          throw e;
        }

        this.$store.commit('auth/mergeInstance', { id: this.instanceId, rights_slugs: [] });
      }
    }

    if (this.section !== 'contacts') {
      this.setContactsOptions({
        ...this.contactsOptions,
        itemsPerPage: 10,
      });
    }

    if (!loaded) {
      return;
    }

    switch (this.section) {
      case 'settings':
        this.$store.commit('global/actions', [
          {
            onClick: () => { this.submit(); },
            color: 'primary',
            disabled: this.isNew,
            icon: 'mdi-content-save',
          },
        ]);
        break;
      case 'contacts':
        this.$store.commit('global/actions', [
          {
            onClick: this.showAdoptionModal,
            color: 'primary',
            disabled: this.isNew || !this.item.can_adopt || this.hasChanged,
            icon: 'mdi-link-plus',
            tooltip: 'Adopter un contact',
          },
        ]);
        break;
      case 'roles':
        this.$store.commit('global/actions', [
          {
            onClick: () => { this.$router.push(`/instances/${this.id}/${this.section}/new`); },
            color: 'primary',
            disabled: this.isNew || this.hasChanged,
            icon: 'mdi-plus',
          },
          {
            onClick: () => {
              this.volunteersMassImportDialogVisible = true;
            },
            color: 'accent',
            disabled: this.isNew || this.hasChanged,
            icon: 'mdi-hand-left',
            tooltip: 'Ajout de militant·es',
          },
          {
            onClick: async () => {
              this.showConfirmationDialog({
                callback: async () => {
                  this.instanceRolesArchiveLoading = true;
                  this.onInstancesFormRouteDataLoadedChanged(true);
                  await Promise.all(this.instanceRoles
                    .map((i: InstanceRole) => (i.is_active ? this.$store.dispatch('instance_roles/archive', i.id) : true)));

                  this.instanceRolesArchiveLoading = false;
                  this.onInstancesFormRouteDataLoadedChanged(true);
                  this.reloadDataRoutesData(['instance_roles.index'], true);
                },
                color: 'warning',
                message: 'Êtes-vous sûr·e de vouloir mettre fin à tous les rôles affichés dans la liste?',
                title: 'Mettre fin à tous les rôles affichés',
              });
            },
            color: 'error',
            disabled: this.instanceRolesLoading,
            icon: 'mdi-archive',
            loading: this.instanceRolesArchiveLoading,
            tooltip: 'Mettre fin à tous les rôles affichés',
          },
          {
            onClick: () => {
              this.usersSettingsDialogVisible = true;
            },
            color: 'info',
            disabled: false,
            icon: 'mdi-cog',
          },
        ]);
        break;
      case 'grants':
        this.$store.commit('global/actions', [
          {
            onClick: () => { this.$router.push(`/instances/${this.id}/${this.section}/new`); },
            color: 'primary',
            disabled: this.isNew || this.hasChanged,
            icon: 'mdi-plus',
          },
          {
            onClick: () => {
              this.usersSettingsDialogVisible = true;
            },
            color: 'info',
            disabled: false,
            icon: 'mdi-cog',
          },
        ]);
        break;
      case 'users':
        this.$store.commit('global/actions', [
          {
            onClick: () => { this.$router.push(`/instances/${this.id}/${this.section}/new`); },
            color: 'primary',
            disabled: this.isNew || this.hasChanged,
            icon: 'mdi-plus',
          },
          {
            onClick: () => {
              this.showConfirmationDialog({
                callback: async () => {
                  this.instanceUsersArchiveLoading = true;
                  this.onInstancesFormRouteDataLoadedChanged(true);
                  await Promise.all(this.instanceUsers
                    .map((i) => (i.is_active ? this.$store.dispatch('instance_users/archive', i) : true)));

                  this.instanceUsersArchiveLoading = false;
                  this.onInstancesFormRouteDataLoadedChanged(true);
                  this.reloadDataRoutesData(['instance_users.index'], true);
                },
                color: 'warning',
                message: 'Êtes-vous sûr·e de vouloir mettre fin à toutes les permissions affichées dans la liste?',
                title: 'Mettre fin à toutes les permissions affichées',
              });
            },
            color: 'error',
            disabled: this.instanceUsersLoading,
            icon: 'mdi-archive',
            loading: this.instanceUsersArchiveLoading,
            tooltip: 'Mettre fin à toutes les permissions affichées',
          },
          {
            onClick: () => {
              this.usersSettingsDialogVisible = true;
            },
            color: 'info',
            disabled: false,
            icon: 'mdi-cog',
          },
        ]);
        break;
      case 'events':
        if (this.userHas('INSTANCE_EVENTS_CREATE')) {
          this.$store.commit('global/actions', [
            {
              onClick: () => { this.$router.push(`/instances/${this.id}/events/new`); },
              color: 'primary',
              icon: 'mdi-plus',
            },
          ]);
        }
        break;
      case 'extra':
        this.$store.commit('global/actions', [
          {
            onClick: () => { this.submit(); },
            color: 'primary',
            disabled: this.isNew || !this.hasChanged,
            icon: 'mdi-content-save',
          },
        ]);
        break;
      default:
        break;
    }

    this.setGlobalTitle();
  }

  setGlobalTitle() {
    this.$store.commit('global/title', this.item?.name || 'Chargement...');
    const subtitlesParts = [];
    if (this.item?.instance_type) {
      subtitlesParts.push(this.item.instance_type.name);
    }
    if (this.item?.region) {
      subtitlesParts.push(this.item.region.name);
    }

    this.$store.commit('global/subtitle', subtitlesParts.join(' · '));

    this.$emit('updateHead');
  }

  tabUrl(section: string) {
    return {
      name: 'InstancesForm',
      params: {
        id: this.$route.params.id,
        section,
      },
    };
  }
}
