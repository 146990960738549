





















































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';

import QsDataTable from 'qs_vuetify/src/components/QsDataTable.vue';
import QsFilters from 'qs_vuetify/src/components/QsFilters.vue';

import AuthenticationMixin from 'qs_vuetify/src/mixins/AuthenticationMixin';
import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';
import NavigationMixin from 'qs_vuetify/src/mixins/NavigationMixin';

import { DataTableOptions } from 'qs_vuetify/src/types/components';
import {
  NationalEvent,
} from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

import fr from 'qs_vuetify/src/locales/fr';

import UsersSettingsDialog from '@/components/Dialog/UsersSettingsDialog.vue';

const global: any = namespace('global');
const store: any = namespace('national_events');
const view: any = namespace('nationalEventsView');

@Component({
  components: {
    QsDataTable,
    QsFilters,
    UsersSettingsDialog,
  },
  head: {
    title(this: NationalEventsList) {
      const { title, subtitle } = this.$store.state.global;
      let inner = title || this.$route.meta.title;
      if (this.section) {
        const section = this.$t(`sections.${this.section}`);
        inner = `${section} | ${inner}`;
      } else if (subtitle) {
        inner = `${subtitle} | ${inner}`;
      }
      return { inner };
    },
  },
})
export default class NationalEventsList extends mixins(
  AuthenticationMixin,
  DataRouteGuards,
  ListMixin,
  NavigationMixin,
) {
  @Prop({ type: String, required: false }) section!: string | null;

  @global.Mutation addNotification!: Function;

  @store.Getter data!: Array<NationalEvent>;
  @store.Getter filtersDefinition!: FiltersDefinition;
  @store.Getter loading!: boolean;
  @store.Getter slug!: string;
  @store.Getter total!: number;

  @view.Getter options!: DataTableOptions;
  @view.Getter params!: RestParams;
  @view.Mutation('setOptions') setOptions!: any;
  @view.Mutation('setParams') setParams!: any;

  setParam = this.buildSetParam('national_events', this.setParams);

  get filtersDefinitionExceptUuids(): FiltersDefinition {
    if (!this.filtersDefinition) {
      return {};
    }

    const {
      minutes_man_assembly_uuid, // eslint-disable-line @typescript-eslint/no-unused-vars
      zoom_meeting_uuid, // eslint-disable-line @typescript-eslint/no-unused-vars
      ...rest
    } = this.filtersDefinition;

    return rest;
  }

  get listActions(): string[] {
    if (!this.userHas('NATIONAL_EVENTS_UPDATE')) {
      return [];
    }

    return ['archive', 'edit', 'restore'];
  }

  tableHeaders = [
    { text: 'ID', value: 'id', width: 100 },
    { text: 'Nom', value: 'name' },
    { text: 'Type', value: 'type' },
    { text: 'Statut', value: 'status', sortable: false },
    { text: 'Participations', value: 'participations_count', sortable: false },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
      width: 100,
    },
  ];

  get viewParams() {
    return {
      national_events: ListMixin.buildListState(
        this.options,
        this.params,
      ),
    };
  }

  async emitArchive(nationalEvent: NationalEvent) {
    try {
      await this.$store.dispatch('national_events/destroy', { id: nationalEvent.id });
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: "Une erreur est survenue en archivant l'événement national.",
      });
    }

    this.reloadDataRoutesData(['national_events.index'], true);
  }

  emitEdit(nationalEvent: NationalEvent) {
    this.goTo(null, {
      name: 'NationalEventsDialog',
      params: { id: `${nationalEvent.id}` },
    });
  }

  async emitRestore(nationalEvent: NationalEvent) {
    try {
      await this.$store.dispatch('national_events/restore', { id: nationalEvent.id });
    } catch (e) {
      this.addNotification({
        color: 'error',
        message: "Une erreur est survenue en archivant l'événement national.",
      });
    }

    this.reloadDataRoutesData(['national_events.index'], true);
  }

  // eslint-disable-next-line class-methods-use-this
  getNationalEventStatusLabel(slug: string): string {
    const nationalEventStatusTranslations = fr.models.national_events.enums.status;
    return nationalEventStatusTranslations[slug] ?? '';
  }

  // eslint-disable-next-line class-methods-use-this
  getNationalEventTypeLabel(slug: string): string {
    const nationalEventTypeTranslations = fr.models.national_events.enums.type;
    return nationalEventTypeTranslations[slug] ?? '';
  }

  goToParticipations(item: NationalEvent): void {
    this.$router.push(`/national_events/${item.id}/participations`);
  }

  setActions() {
    if (this.userHas('NATIONAL_EVENTS_CREATE')) {
      this.$store.commit('global/actions', [
        {
          onClick: () => { this.$router.push('national_events/new'); },
          color: 'primary',
          disabled: false,
          icon: 'mdi-plus',
        },
      ]);
      return;
    }

    this.$store.commit('global/actions', []);
  }

  @Watch('$route', { deep: true })
  onNationalEventsListRouteChanged() {
    this.setActions();
  }

  @Watch('routeDataLoaded')
  onNationalEventsListRouteDataLoadedChanged(loaded: boolean) {
    this.setActions();

    if (loaded && this.data.length === 0 && this.options.page !== 1) {
      this.setOptions({
        ...this.options,
        page: 1,
      });
    }
  }
}
