var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "national-events-form" },
    [
      _vm.item
        ? _c("qs-form-builder", {
            attrs: {
              "model-name": _vm.slug,
              form: _vm.form,
              item: _vm.item,
              error: _vm.error,
              order: [
                "name",
                "description",
                "important_dates",
                "type",
                "registration_end_at",
                "status",
                "minutes_man_assembly_uuid",
                "zoom_meeting_uuid"
              ]
            },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "important_dates",
                  fn: function(ref) {
                    var item = ref.item
                    var update = ref.update
                    return [
                      _c("div", [_vm._v("Dates importantes")]),
                      _c("qs-html-editor", {
                        attrs: { value: item.important_dates },
                        on: {
                          input: function($event) {
                            return update("important_dates", $event)
                          }
                        }
                      })
                    ]
                  }
                },
                {
                  key: "description",
                  fn: function(ref) {
                    var item = ref.item
                    var update = ref.update
                    return [
                      _c("div", [_vm._v("Description")]),
                      _c("qs-html-editor", {
                        attrs: { value: item.description },
                        on: {
                          input: function($event) {
                            return update("description", $event)
                          }
                        }
                      })
                    ]
                  }
                }
              ],
              null,
              false,
              964982528
            )
          })
        : _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }